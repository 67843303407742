<template>
  <layout page="config-form">
    <page-title icon="lni lni-cog" :breadcrumb="breadcrumb">Configurações</page-title>
    <div class="card">
      <div class="card-content">
        <div class="form">
          <error :error="error" />

          <div class="field">
            <label class="label" for="about">Sobre<asterisk /></label>
            <markdown-editor v-model="about" name="about" id="about" placeholder="Descrição da atividade" />
            <p class="help">Markdown syntax disponível, <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">veja mais</a>.</p>
          </div>

          <div class="field">
            <label class="label" for="contactEmail">Email de Contato<asterisk /></label>
            <div class="control">
              <input v-model="contactEmail" class="input" type="text" name="contactEmail" id="contactEmail" placeholder="Titulo da atividade" ref="contactEmail" />
            </div>
            <p class="help">Email para qual as mensagens de contato são enviadas</p>
          </div>

          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send">Salvar Configuração</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import { DASHBOARD } from '@/config/breadcrumb/teacher'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Error from '@/components/ui/Error'
import MarkdownEditor from '@/components/MarkdownEditor'
import Asterisk from '@/components/ui/Asterisk'

export default {
  name: 'ConfigForm',
  components: {
    Layout,
    PageTitle,
    Error,
    MarkdownEditor,
    Asterisk
  },
  created () {
    api.get(`/configs/${this.id}`).then(res => {
      this.about = res.data.about
      this.contactEmail = res.data.contactEmail
    }).catch(err => {
      this.enviando = false
      if (err.response) {
        console.log(err.response)
        if (err.response.status === 404) {
          api.post('/configs', {
            about: '',
            contactEmail: ''
          }).then(() => {
            this.error = null
          }).catch(() => {
            this.error = 'Erro fatal! Atualize a página'
          })
        }
        this.error = err.response.data.message
      } else {
        this.error = 'Servidor não responde'
      }
    })
  },
  data () {
    return {
      id: 1,
      about: '',
      contactEmail: '',
      error: null,
      enviando: false
    }
  },
  computed: {
    breadcrumb () {
      return [
        DASHBOARD,
        {
          text: 'Configuração',
          path: '',
          isActive: true
        }
      ]
    }
  },
  methods: {
    send () {
      this.enviando = true

      if (this.about === '') {
        this.error = 'Sobre é obrigátorio'
        this.$refs.about.focus()
        this.enviando = false
        return
      }
      this.error = null

      if (this.contactEmail === '') {
        this.error = 'Email de contato é obrigátorio'
        this.$refs.contactEmail.focus()
        this.enviando = false
        return
      }
      this.error = null

      api.patch(`/configs/${this.id}`, {
        about: this.about,
        contactEmail: this.contactEmail
      }).then(res => {
        this.error = null
        this.enviando = false
      }).catch(err => {
        this.enviando = false
        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    }
  }
}
</script>
